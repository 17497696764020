<template>
  <t-card variant="notification" :class="[{
      'border-badgeError bg-badgeErrorPale':type==='error',
      'border-badgeInfo bg-badgeInfoPale':type==='info',
      'border-badgeSuccess bg-badgeSuccessPale':type==='success'
  }]">
    <div class="w-full flex flex-row justify-center items-center m-auto">
      <div class="w-full lg:w-11/12 flex flex-col items-center justify-center gap-3"
      >
        <div class="w-full text-center text-lg lg:text-base">
          <slot name="section1"/>
        </div>
        <div v-if="sectionCount>1"
          class="w-full text-center text-base lg:text-sm"
          :class="{'text-badgeError':type==='error', 'text-badgeInfo':type==='info', 'text-badgeSuccess':type==='success', '-mt-3':sectionCount>2}"
        >
          <slot name="section2"/>
        </div>
        <div v-if="sectionCount>2"
          class="w-full text-center text-base lg:text-sm"
          :class="{'text-badgeError':type==='error', 'text-badgeInfo':type==='info', 'text-badgeSuccess':type==='success'}"
        >
          <slot name="section3"/>
        </div>
      </div>
    </div>
  </t-card>
</template>

<script>
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
export default {
  name: "Notification",
  computed:{
    isMobile() {
      return checkIsMobileDevice();
    },
  },
  props: {
    sectionCount: Number,
    type:String,//[succes,info,error]
  }
}

</script>
