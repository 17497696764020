<template>
  <div v-if="!userData.hasFullAccess || !userData.isSubscriptionActive">
    <!-- 1. Banned user -->
    <MainContainerNotification
      v-if="userData.isBanned"
      :section-count=1
      type="error"
    >
      <template v-slot:section1>
        {{ trans(context==='search'?'MESSAGE:SearchIsBlockedBecauseOfBan':'MESSAGE:ChatIsBlockedBecauseOfBan') }}
      </template>
    </MainContainerNotification>
    <!-- 2. Unverified user -->
    <MainContainerNotification
      v-else-if="!userData.isVerified"
      :section-count=2
      type="error"
    >
      <template v-slot:section1>
        {{ trans(context==='search'?'MESSAGE:SearchIsUnavailableForUnverified':'MESSAGE:ChatIsUnavailableForUnverified') }}
      </template>
      <template v-slot:section2>
        <Button
          class="mt-3"
          variant="buttonRed"
          :to="{name: 'verification-details'}"
          :text="trans('BUTTON:VerifyIdentity')"
        />
      </template>
    </MainContainerNotification>
    <!-- 3. Incomplete user profile -->
    <MainContainerNotification
      v-else-if="!userData.isProfileComplete"
      :section-count=2
      type="error"
    >
      <template v-slot:section1>
        {{ trans(context==='search'?'MESSAGE:SearchIsUnavailableForIncompleteProfiles':'MESSAGE:ChatIsUnavailableForIncompleteProfiles') }}
      </template>
      <template v-slot:section2>
        <Button
          class="mt-3"
          variant="buttonRed"
          :to="{name: 'profile'}"
          :text="trans('BUTTON:ProfileEdit')"
        />
      </template>
    </MainContainerNotification>
    <!-- 4. No subscription -->
    <MainContainerNotification
      v-else-if="!userData.isSubscriptionActive"
      :section-count=2
      type="error"
    >
      <template v-slot:section1>
        {{ trans(context==='search'?'MESSAGE:BuySubscriptionToUnlockSearch':'MESSAGE:BuySubscriptionToUnlockChats') }}
      </template>
      <template v-slot:section2>
        <Button
          class="mt-3"
          variant="buttonRed"
          :to="{name: 'subscription'}"
          :text="trans('BUTTON:BuySubscription')"
        />
      </template>
    </MainContainerNotification>
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import MainContainerNotification from "@/views/content/components/MainContainerNotification.vue"

export default {
  name: "UserProblemNotifications",
  components:{
    Button,
    MainContainerNotification,
  },
  props: {
    userData: Object,
    adToShow:String,
    context: String,
  },
}
</script>
