<template>
  <PageContainer>
    <PageTitle v-if="!isMobile || isMobile && !currentThread">
      <div class="flex flex-row items-center gap-3">
        <div>
          {{ trans('PAGE_TITLE:Chats') }}
        </div>
        <VTooltip>
          <span class="flex flex-row items-center">
            <Button
              variant="link"
              @clicked="openBeforeYouStartChattingModal(true)"
            >
              <base-icon  name="info" width="20" height="20"/>
            </Button>
          </span>
          <template #popper>
            {{ trans('LABEL:BeforeYouStartChatting') }}
          </template>
        </VTooltip>
        <BadgeFrame v-if="userData && userData.isBanned">
          <Badge tagName="span" variant="badgeError">
            {{ trans('LABEL:AccountBlocked') }}
          </Badge>
        </BadgeFrame>
      </div>
    </PageTitle>

    <PageContentWithSidebar>
      <!-- Sidebar -->
      <Sidebar
        class="max-h-main-container"
        :class="{ 'hidden': chatActiveMobile}"
        v-show="isMobile ? !messagesLoading && !threadsLoading : true"
      >
        <Loader
          v-show="threadsLoading && !isMobile"
          class="w-sidebar h-sidebar"
        />
        <Threads
          v-show="isMobile || (!isMobile && !threadsLoading)"
          :user-data="userData"
        />
      </Sidebar>

      <!-- Page content -->
      <div class="w-full lg:w-main-container flex flex-col gap-y-3 lg:gap-y-6">
        <Loader
          v-if="threadsLoading || messagesLoading || !threadsData"
          class="w-full lg:h-sidebar"
          :class="getHeightOfScreenWidth(1)"
        />
        <template v-else>
        <!-- User-based problems  -->
          <template v-if="userData">
            <UserProblemNotifications
              :user-data=userData
              :ad-to-show=adToShow
              context="chat"
            />
          </template>

          <!-- Notification not related to user problems -->
          <template v-if="userData.hasFullAccess">

            <!-- Message to make new relationship -->
            <MainContainerNotification
              v-show="threadsData && threadsData.length === 0"
              :section-count=3
              type="info"
            >
              <template v-slot:section1>
                {{ trans('MESSAGE:AddNewContactToStartChat1') }}
              </template>
              <template v-slot:section2>
                {{ trans('MESSAGE:AddNewContactToStartChat2') }}
              </template>
              <template v-slot:section3>
                <Button
                  variant="navLink"
                  icon="newContactsFilled"
                  class="text-black text-lg"
                  :to="{name: 'search'}"
                  :text="trans('BUTTON:NewContacts')"
                />
              </template>
            </MainContainerNotification>

            <!-- Message to open some chat -->
            <MainContainerNotification
              v-show="threadsData && threadsData.length > 0 && !currentThread"
              :section-count=1
              type="success"
            >
              <template v-slot:section1>
                {{ trans('MESSAGE:ChoosePersonToStartChat') }}
              </template>
            </MainContainerNotification>

          </template>

          <!-- Advertising content -->
          <template v-if="adToShow !== null && (!userData.hasFullAccess || !currentThread)">
            <t-card class="w-full flex flex-col justify-center items-center lg:w-main-container lg:min-h-sidebar">
              <PostShow :slug="adToShow" :no-outer-padding=true :no-timestamp=true />
            </t-card>
          </template>

          <!-- Chat content -->
          <template v-if="currentThread">
            <template v-if="!userData.isAdmin && (currentThread && currentThread.isAdminMessage)">
              <Chat
                ref="chat"
                class="w-full lg:w-main-container"
                :class="{
                  ' h-full-webkit': isChatOpenOnMobile(),
                  'max-h-main-container': !isChatOpenOnMobile()
                }" :key="currentThread.id"
              />
            </template>
            <template v-else>
              <Chat
                ref="adminChat"
                class="w-full lg:w-main-container"
                :class="{
                  'h-full-webkit': isChatOpenOnMobile(),
                  'max-h-main-container': !isChatOpenOnMobile()
                }"
                :key="currentThread.id"
              />
            </template>
          </template>
        </template>
      </div>
    </PageContentWithSidebar>

    <!-- ADD TO FAVOURITES BUTTON END -->
    <Modal
        v-if="showReportUserModal"
        :showModal="showReportUserModal"
        :buttonConfirmText="trans('BUTTON:Yes')"
        @onConfirm="openContactModal(true)"
        :loading="banLoading"
        @clicked="openReportUserModal(false)"
        :header="trans('MODAL_TITLE:ReportUser')"
        :data="{username: threadToReport.targetUser.username}"
    >
      <template v-slot:content>
        <div class="text-base">
          {{ trans('MODAL_DESCRIPTION:ReportUser') }}
        </div>
      </template>
      <template v-slot:footer>
        <Button
          variant="buttonRedOutlined"
          :text="trans('BUTTON:BanUserAndReport')"
          @clicked="openContactModal(true)"
        >
        </Button>
        <Button
          :text="trans('BUTTON:BanUser')"
          @clicked="banAction(true)"
        >
        </Button>
      </template>
    </Modal>
    <Modal
      v-if="isShowContactModal"
      :showModal="isShowContactModal"
      :hide-footer="true"
      @clicked="openContactModal(false)"
      @onConfirm="deleteMessageThread()"
      :header="trans('MODAL_TITLE:ContactForm')"
    >
      <template v-slot:content>
        <ContactForm :reported-user="threadToReport.targetUser" :type="'VIOLATION'" @send-admin-message="banAction(true)" />
      </template>
    </Modal>

    <Modal
      v-if="isShowDeleteChatModal"
      :cancel-button-default="true"
      :showModal="isShowDeleteChatModal"
      @clicked="openDeleteChatModal(false)"
      :header="trans('MODAL_TITLE:DeleteChat')"
      :buttonConfirmText="trans('BUTTON:Delete')"
      :loading="threadDeleting"
      @onConfirm="deleteMessageThread"
    >
      <template v-slot:content>
        <span class="text-base">{{ trans('MODAL_DESCRIPTION:DeleteChat') }}</span>
      </template>
    </Modal>

    <Modal
      v-if="isShowCloseThreadModal"
      :showModal="isShowCloseThreadModal"
      @clicked="openCloseThreadModal(false)"
      :header="trans('MODAL_TITLE:CloseThread')"
      :buttonConfirmText="trans('BUTTON:Close')"
      :loading="threadDeleting"
      @onConfirm="closeThreadAction"
    >
      <template v-slot:content>
        <span class="text-base">{{ trans('MODAL_DESCRIPTION:CloseThread') }}</span>
      </template>
    </Modal>

    <Modal
      hide-footer
      variant="post"
      v-if="isShowBeforeYouStartChattingModal"
      :show-modal="isShowBeforeYouStartChattingModal"
      @clicked="openBeforeYouStartChattingModal(false)"
    >
      <template #content>
        <PostShow slug="before-you-start-chatting" :key="'postBefore'" />
      </template>
    </Modal>
  </PageContainer>

</template>

<script>
import Threads from "@/views/content/messages/Threads";
import Chat from "@/views/content/messages/Chat";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import PageContainer from "@/layouts/components/pages/PageContainer";
import Loader from "@/views/Loader";
import PageTitle from "@/layouts/components/pages/PageTitle";
import PageContentWithSidebar from "@/layouts/components/pages/PageContentWithSidebar"
import Sidebar from "@/layouts/components/pages/Sidebar"
import {isEmpty, getHeightOfScreenWidth, isChatOpenOnMobile} from "@/common/helpers/utils";
import router from "@/router";
import Badge from "@/utils/crud/components/Badge";
import Button from "@/utils/crud/components/Button";
import BaseIcon from "@/utils/icons/BaseIcon";
import BadgeFrame from "@/utils/crud/components/BadgeFrame";
import Hint from "@/views/content/components/Hint";
import Modal from "@/utils/modal/Modal.vue";
import ContactForm from "@/views/content/components/ContactForm.vue";
import {BANNED, FAVOURITES, REGULAR, REPORTS} from "@/common/helpers/thread-types";
import PostShow from "@/views/content/posts/PostShow.vue";
import MainContainerNotification from "@/views/content/components/MainContainerNotification.vue"
import UserProblemNotifications from "@/views/content/components/UserProblemNotifications";

export default {
  name: "Messages",
  components: {
    ContactForm,
    Modal,
    BadgeFrame,
    Badge,
    BaseIcon,
    Button,
    PageTitle,
    Loader,
    Chat,
    Threads,
    PageContainer,
    Hint,
    PageContentWithSidebar,
    Sidebar,
    PostShow,
    MainContainerNotification,
    UserProblemNotifications
  },
  data() {
    return {
      isMobile: checkIsMobileDevice(),
      areThreadsLoaded: false,
      currentThreadId: null,
      allowOpenThread: false,
      initialLoadingThreads: false,
      getHeightOfScreenWidth: getHeightOfScreenWidth,
      showReportUserModal: false,
      isShowContactModal: false,
      isShowDeleteChatModal: false,
      isShowCloseThreadModal: false,
      threadToDelete: null,
      threadToReport: null,
      threadToClose: null,
      isShowBeforeYouStartChattingModal: false
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(val) {
        if(val && this.allowOpenThread) {
          this.currentThreadId = val;
          this.openThreadRequest(this.threadsData.find(el => el.id === Number(this.currentThreadId)));
        }
      }
    },
    allowOpenThread(val) {
      if(val && this.$route.params.id) {
        const thread = this.threadsData.find(el => el.id === Number(this.$route.params.id));
        if(isEmpty(thread)) {
          router.push({name: 'chats'});
          return;
        }
        this.openThreadRequest(thread);
      }
    },
    threadsLoadingCount(val) {
      if(val === 0) {
        this.allowOpenThread = true;
	      this.isShowBeforeYouStartChattingModal = this.threadsData.length === 0;
      }
    },
    threadDeleted(val) {
      if(val) {
        this.openDeleteChatModal(false);
        this.threadToDelete = null;
        this.fetchAllThreads(true)
      }
    },
    banned(val) {
      if(val) {
        this.openReportUserModal(false);
        this.openContactModal(false);
      }
      this.fetchAllThreads(true)
    },
    threadClosed(val) {
      this.openCloseThreadModal(false);
      this.threadToClose = false;
    },
    adminMessageSent(val) {
      if(val) {
        this.openReportUserModal(false);
        this.openContactModal(false);
      }
    },
    favUserAddedOrRemoved(val) {
      this.fetchAllThreads(true)
    },
    fetchAllThreadsSignal: {
      immediate: true,
      handler(val) {

        if(val) {
          this.fetchAllThreads(true)
          this.setFetchAllThreadsSignal(false);
        }
      }
    },
  },
  computed: {
    ...mapGetters('chat',
      [
        'currentThread', 'threadsData', 'chatActiveMobile', 'messagesLoading',
        'threadsLoaded', 'threadsLoading', 'dontShowDeletedUsersDone',
        'banLoading', 'threadDeleting', 'banned', 'threadDeleted', 'threadClosing', 'threadOpening',
        'threadClosed', 'threadsLoadingCount', 'fetchAllThreadsSignal', 'adminMessageThreadId'
      ]),
    ...mapGetters('favourites', ['favUserAddedOrRemoved']),
    ...mapGetters('contact', ['adminMessageSent']),
    ...mapGetters('account', ['userData']),
    ...mapGetters('lang', ['langLocale']),
    ...mapGetters('ads', ['adToShow', 'adLoading']),
  },
  methods: {
    isChatOpenOnMobile,
    ...mapMutations('chat', ['setThreadsData', 'setEmptyThreadsData', 'setLoadedThreads', 'setThreadsLoadingCount', 'setFetchAllThreadsSignal', 'setAdminMessageThreadId']),
    ...mapActions('chat', ['fetchMessageThreadsData', 'openThreadRequest', 'banThreadRequest', 'deleteThreadRequest', 'openThreadByAdminRequest', 'closeThreadByAdminRequest']),
    ...mapActions('favourites', ['fetchFavouriteUsers', 'removeFavouriteProfile']),
    ...mapActions('ads', ['fetchAd']),

    banAction(value) {
      if(this.threadToReport.userFavouritedBy) {
        this.removeFavouriteProfile({favouriteUsername: this.threadToReport.targetUser.username})
      }
      this.banThreadRequest({
        threadId: this.threadToReport.id,
        ban: value,
      });
      this.$root.$emit('ban-action')
    },

    deleteMessageThread() {
      this.deleteThreadRequest({threadId: this.threadToDelete.id})
      this.$root.$emit('delete-fav-action')
    },

    closeThreadAction() {
      this.closeThreadByAdminRequest({threadId: this.threadToClose.id});
      this.$root.$emit('send-close-thread-message');
    },

    openReportUserModal(value, ban = false) {
      this.showReportUserModal = value;

      if(ban) {
        this.banAction(true);
      }
    },

    openContactModal(value, ban = false) {
      this.isShowContactModal = value;

      if(ban) {
        this.banAction(true);
      }
    },

    openDeleteChatModal(value, threadToDelete = null) {
      this.isShowDeleteChatModal = value;
      this.threadToDelete = threadToDelete;
    },
    openCloseThreadModal(value, threadToClose = null) {
      this.isShowCloseThreadModal = value;
      this.threadToClose = threadToClose;
    },
    fetchAllThreads(noLoader = false) {
      this.fetchMessageThreadsData({type: REGULAR, noLoader: noLoader});
      this.fetchMessageThreadsData({type: BANNED, noLoader: noLoader});
      this.fetchMessageThreadsData({type: REPORTS, noLoader: noLoader});
      this.fetchMessageThreadsData({type: FAVOURITES, noLoader: noLoader});
    },

    openBeforeYouStartChattingModal(value) {
      this.isShowBeforeYouStartChattingModal = value;
    },
  },
  mounted() {
    this.fetchAllThreads();
    this.fetchFavouriteUsers();
    this.$root.$on('open-delete-modal', (value, thread) => {
      this.openDeleteChatModal(value, thread)
    });
    this.$root.$on('ban-action-modal', (value, thread) => {
      this.threadToReport = thread

      this.banAction(value, thread)
    });

    this.$root.$on('open-close-thread-modal', (value, thread) => {
      this.openCloseThreadModal(value, thread)
    });

    this.$root.$on('open-report-modal', (thread) => {
      this.threadToReport = thread

      this.showReportUserModal = true;
    });

		this.fetchAd();
  },
	beforeDestroy() {
    this.$root.$off('open-delete-modal')
    this.$root.$off('ban-action-modal')
    this.$root.$off('open-report-modal')
    this.$root.$off('send-close-thread-message')
    this.setEmptyThreadsData();
    this.setLoadedThreads(false);
    this.setThreadsLoadingCount(4);
    this.setAdminMessageThreadId(null);
  },

}
</script>

<style lang="scss">

.h-full-webkit  {
  height: calc(100dvh - 128px);
  //height: 100dvh;
  //min-height: 100dvh;
  //min-height: -webkit-fill-available;
}
</style>
