<template>
	<div class="flex flex-col flex-auto bg-white border-t lg:rounded-lg lg:border border-grayed  custom-flex-chat h-main-container" ref="chatPage" :class="{'inset-0 fixed': isChatOpenOnMobile()}">
		<div :class="{'w-full z-index-999 top-0 sticky': isChatOpenOnMobile()}" id="toolbar-wrap">
			<div class="px-3 lg:px-6 pt-3 lg:pt-4 pb-3 flex flex-row flex-none justify-between items-center border-b border-dashed border-grayed bg-white rounded-t-md" :class="{'w-full chat-header left-0 right-0 gap-3 absolute': isChatOpenOnMobile()}" id="toolbar"
        v-if="currentThread"
        ref="chatNavigation"
			>
				<div class="w-full flex flex-row items-center gap-2">
					<div class="w-full flex flex-row items-center justify-start">
						<Button v-if="isMobile"
              class="mr-2"
              variant="icon"
              @clicked="closeThread"
						>
							<base-icon :height="24" :width="24" name="chevronLeft"/>
						</Button>
						<div class="w-full flex flex-row gap-3 group items-center cursor-default" :class="{'cursor-pointer': !currentThread.isAdminMessage || (currentThread.isAdminMessage && userData.isAdmin)}">
							<template v-if="!currentThread.isAdminMessage || (currentThread.isAdminMessage && userData.isAdmin)">
								<div class="flex-none" @click="showProfileModal(currentThread.targetUser.username)">
									<Picture
                    :loader-enabled="true"
                    is-chat
                    :src="currentThread.targetUser.featuredPictureUrl"
                    classes="transform ease-out duration-200 object-cover w-10 h-10 rounded-full border border-black
                  group-hover:border-redAccent lg:group-hover:scale-105"
									/>
									<ProfilePictureIcons
                    :banned-flag="currentThread.targetUser.isBanned"
                    :hidden-flag="currentThread.targetUser.isHidden"
                    :icon-size=2.5
                    :horizontal-offset=1.5
									/>
								</div>
								<VTooltip>
									<div class="w-full flex flex-row block text-xl lg:text-lg font-semibold
                    group-hover:text-redAccentLight group-hover:opacity-70
                    whitespace-nowrap"
                    :class="getWidth('ChatUsername', 708,0)"
                    @click="showProfileModal(currentThread.targetUser.username)"
                  >
										<span class="truncate">{{ currentThread.targetUser.name }}</span>
									</div>
									<template #popper>
										{{ trans('TOOLTIP:DisplayProfile') }}
									</template>
								</VTooltip>
							</template>
							<template v-else class="relative flex-none">
								<Picture
                  :loader-enabled="true"
                  is-chat
                  :src="systemAvatar"
                  classes="object-cover w-7 h-7 rounded-full border border-black"
								/>
								<div class="flex flex-row block text-lg lg:text-base font-semibold
	                whitespace-nowrap"
								>
			          <span class="truncate">
                    {{ trans('LABEL:AdministratorName') }}
			          </span>
								</div>
							</template>
						</div>
					</div>
				</div>
				<div v-if="!currentThread.targetUser.isDeleted" class="relative flex flex-row items-center gap-6">
          <Button
            class="z-30 top-0 -right-3"
            v-show="isMobile && isThreadActionsDropdownVisible"
            variant="iconThreadAction"
            @clicked="toggleThreadActionsDropdown()"
          >
            <base-icon color="fill-grayed" :width="16" :height="16" name="x"/>
          </Button>
          <Button
            class="z-30"
            v-show="isMobile"
            variant="iconThreadAction"
            @clicked="toggleThreadActionsDropdown()"
          >
            <base-icon :height="24" :width="24" name="dotsHorizontal"/>
          </Button>
          <ThreadActions
            v-show="!isMobile || isThreadActionsDropdownVisible"
            :current-thread="currentThread" :only-delete="currentThread.isAdminMessage"
          />
        </div>
			</div>

		</div>
		<!--  MESSAGE CHAT SECTION  -->
		<div class="relative flex-1 overflow-y-scroll px-3 lg:px-6 py-4" :class="{'chat-content': isChatOpenOnMobile()}"
		     ref="chatContainer"
		>
      <div class="absolute left-1/2 transform -translate-x-1/2"
           v-show="messagesPagination.last_page > 1
        && messagesPagination.current_page !== messagesPagination.last_page"
      >
        <Button variant="link"
                class="text-s inline-flex gap-2 items-center pr-3"
                @clicked="loadMoreMessages"
                :loading="newMessagesLoading"
        >
          <base-icon name="cloudDownloadFilled"/>
          {{ trans('BUTTON:LoadMore') }}
        </Button>
      </div>
      <ul class="space-y-0">
        <li class="flex flex-col justify-start message-box"
            v-for="(message, index) in messagesData"
            :key="index"
            :class="{
          'justify-end' : checkIsSender(message.sender.username),
          'border-b border-white':isNextFromTheSameSender(message, index),
          'mt-8': messagesPagination.last_page > 1
             && messagesPagination.current_page !== messagesPagination.last_page
        }"
        >
          <template v-if="(isPreviousFromYesterday(message,index) || index === 0)
          && !checkIsSystemMessage(message.sender)"
          >
            <div class="flex flex-row w-full items-center justify-center text-xs text-grayed py-3">
              {{ message.created_at_date }}
            </div>
          </template>
          <div class="flex flex-row items-start"
               :class="{
            'justify-end':checkIsSender(message.sender.username),
            'mb-3':!isNextFromTheSameSender(message, index) && index !== messagesData.length-1,
            'mb-1':isNextFromTheSameSender(message, index) && isNextSeparated(message, index)
          }"
          >
            <div class="flex-none">
              <template v-if="!checkIsSender(message.sender.username) && message.showImage && message.sender !== systemSender && (!currentThread.isAdminMessage || currentThread.isAdminMessage && userData.isAdmin)"
              >
                <div
                    class="cursor-pointer relative mr-2 mt-1.5"
                    @click="showProfileModal(currentThread.targetUser.username)"
                >
                  <Picture
                      :loader-enabled="true"
                      is-chat
                      :src="currentThread.targetUser.featuredPictureUrl"
                      classes="object-cover w-7 h-7 rounded-full border border-black
                      transform duration-200 lg:hover:scale-105 hover:border-redAccent"
                  />
                  <ProfilePictureIcons
                      :banned-flag="currentThread.targetUser.isBanned"
                      :hidden-flag="currentThread.targetUser.isHidden"
                      :icon-size=2.5
                      :horizontal-offset=1.5
                  />
                </div>
              </template>
              <template v-if="!checkIsSender(message.sender.username)
              && !message.showImage && message.sender !== systemSender"
              >
                <!-- No picture placeholder -->
                <div class="w-7 mr-2"/>
              </template>
              <template v-if="!userData.isAdmin && (checkIsSystemMessage(message.sender) || (currentThread.isAdminMessage && !checkIsSender(message.sender.username) && message.showImage))">
                <img
                    :src="systemAvatar"
                    alt="app logo"
                    class="object-cover w-7 h-7 border border-black rounded-full mr-2"
                />
              </template>
            </div>
            <div class="flex flex-row items-center py-1 mr-1" v-if="message.sendingFailed">
              <VTooltip>
                <base-icon class="w-4 h-4" name="error" color="fill-transparent"/>
                <template #popper>
                  {{ trans('TOOLTIP:MessageSentFailed') }}
                </template>
              </VTooltip>
            </div>
            <div class="relative lg:max-w-md max-w-xs px-3 py-2 rounded-xl"
                 :class="[
              {
                'bg-white border border-black mr-6 lg:mr-0': checkIsSystemMessage(message.sender),
                // Wlasne wiadomosci
                'bg-grayedLight text-black ml-12 lg:ml-0' : checkIsSender(message.sender.username),
                'rounded-tr-sm':isPreviousFromTheSameSender(message, index) && checkIsSender(message.sender.username)
                  && !isPreviousSeparated(message, index),
                'rounded-br-sm':isNextFromTheSameSender(message, index) && checkIsSender(message.sender.username)
                  && !isNextSeparated(message, index),
                'border border-badgeError' : message.sendingFailed,
                // Cudze wiadomosci
                'mr-6 lg:mr-0':!checkIsSender(message.sender.username),
                'rounded-tl-sm':isPreviousFromTheSameSender(message, index) && !checkIsSender(message.sender.username)
                  && !isPreviousSeparated(message,index),
                'rounded-bl-sm':isNextFromTheSameSender(message, index) && !checkIsSender(message.sender.username)
                  && !isNextSeparated(message,index),
                'pt-1':isPreviousFromTheSameSender(message, index) && !isPreviousSeparated(message, index),
                'pb-1':isNextFromTheSameSender(message, index) && !isNextSeparated(message, index),
              },
              currentThread.isAdminMessage ? 'bg-badgeInfoPale border border-badgeInfo' : getChatColor(message) ? chatColor : ''
            ]">
            <span :ref="index === messagesData.length - 1 ? 'lastMessage' : ''"
                  class="block break-words text-pretty text-xl lg:text-base leading-normal"
                  v-html="message.content"
            >
            </span>
            </div>
          </div>
        </li>
      </ul>
		</div>
		<!--  END MESSAGE CHAT SECTION  -->

		<!--  TRIX AND BOTTOM SETTINGS SECTION  -->
		<div :class="{'chat-footer': isChatOpenOnMobile()}"
      class="flex-none z-index-2 flex-1 row-span-4 row-start-8 row-end-13 messages-chat
        border-t border-dashed border-grayed"
      v-if="currentThread"
      ref="messageForm"
		>
			<ChatForm
        :onEnterSend="!!onEnterSend"
        :key="onEnterSend"
        @scroll-chat="scrollToBottomPosition = true"
        :editor-disabled-prop="currentThread.systemMessage !== null || userData.isBanned"
        @chat-focus="showChatNavigationFocusIn"
        @chat-blur="showChatNavigationBlur"
        @chat-scrolled="chatScrolled"
			>
				<template v-slot:onEnterSend>
					<CheckboxField
            v-model="onEnterSend"
            :text-base="isMobile?true:false"
            class="text-xl lg:text-base hover:opacity-70 hover:text-redAccentLight"
            :label="trans('LABEL:SendOnEnter')"
					/>
				</template>
			</ChatForm>
		</div>
		<!--  END TRIX AND BOTTOM SETTINGS SECTION  -->

		<Modal hide-footer variant="profileNoMenuOnMobile" v-if="isShowProfileModal" :show-modal="isShowProfileModal" @clicked="isShowProfileModal = false">
			<template #content>
				<ProfileGuest :on-modal=true :username="profileUsername" key="profileChatModal"/>
			</template>
		</Modal>

	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {mdiCheck, mdiCheckAll, mdiKeyboardReturn} from '@mdi/js'
import ChatForm from "@/views/content/messages/ChatForm";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import Button from "@/utils/crud/components/Button";
import BaseIcon from "@/utils/icons/BaseIcon";
import CheckboxField from "@/utils/crud/components/field-types/Checkbox";
import themeConfig from "@/themeConfig";
import Modal from "@/utils/modal/Modal";
import ThreadActions from "@/views/content/messages/components/ThreadActions";
import router from "@/router";
import {chatColors} from "@/config/chatColors";
import ProfileGuest from "@/views/content/profile/ProfileGuest";
import {getWidth, isChatOpenOnMobile} from "@/common/helpers/utils";
import ProfilePictureIcons from "@/views/content/components/ProfilePictureIcons";
import ContactForm from "@/views/content/components/ContactForm.vue";
import resizeChatHeader from "@/common/helpers/resize-chat-header";

export default {
	name: "Chat",
	components: {
		ContactForm,
		BaseIcon,
		Button,
		ChatForm,
		CheckboxField,
		Modal,
		ProfileGuest,
		ProfilePictureIcons,
		ThreadActions,
	},
	data() {
		return {
			getWidth: getWidth,
			messageContent: '',
			search: '',
			isMobile: checkIsMobileDevice(),
			systemSender: 'SYSTEM',
			systemAvatar: themeConfig.app.logo,
			isNewContact: false,
			hasScrolledToTop: false,
			showLoadMoreButton: false,
			currentPage: 1,
			intervalToSeparateMessages:30*1000,
			chatColors: chatColors,
			scrollToBottomPosition: false,
			isShowProfileModal: false,
			profileUsername: '',
      isThreadActionsDropdownVisible: false,
    }
	},

	computed: {
		...mapGetters('chat',
				[
					'messagesData', 'messagesLoaded',
					'chatActiveMobile', 'chosenUser', 'currentThread',
					'threadsData', 'threadsLoaded', 'banLoading', 'chatErrors', 'messagesPagination',
					'newMessagesLoading', 'threadDeleting', 'threadDeleted'
				]),
		...mapGetters('account', ['userData']),
		...mapGetters('contact', ['adminMessageSent']),

		onEnterSend: {
			get() {
				if(this.$store.getters['chat/onEnterSend'] === null) {
					return this.userData.onEnterSend
				}
				return this.$store.getters['chat/onEnterSend'];
			},
			set(value) {
				this.sendOnEnterSendRequest({onEnterSend: value})
			}
		},
		chatColor () {
			let colorObj = this.chatColors.find(el => el.name === this.currentThread.color);
			return colorObj.text + ' ' + colorObj.color;
		}
	},
	watch: {
		'$route.params.id': {
			immediate: true,
			handler(val) {
				if(val === undefined) {
					this.clearCurrentThread();
				}
			}
		},
		messagesLoaded: {
			immediate: true,
			handler(val) {
				if(val) {
					this.$nextTick(() => {
						this.scrollToBottom()
					})
				}
			}
		},
		messagesData() {
			let initialHeight = this.$refs.chatContainer.scrollHeight
			let vm = this;
			this.$nextTick(() => {
				if(this.messagesPagination.current_page === 1 || this.scrollToBottomPosition) {
					this.scrollToBottom();
				} else {
					this.scrollToNewPosition(vm.$refs.chatContainer.scrollHeight, initialHeight);
				}
			})
		},
		'currentThread.systemMessage': {
			immediate: true,
			deep: true,
			handler(val) {
				if(val !== null) {
					this.addSystemMessage(val);
				}
			}
		},
		hasScrolledToTop(val) {
			setTimeout(() => {
				this.showLoadMoreButton = val;
			}, 300);
		},

	},
	methods: {
		isChatOpenOnMobile,
		...mapActions('chat', [
			'openThreadRequest', 'fetchMessageThreadsData', 'sendMessageRequest',
			'setCurrentThreadAction', 'clearCurrentThread', 'changeReadRequest', 'sendNewContactMessageRequest',
			'banThreadRequest', 'sendOnEnterSendRequest', 'loadMoreMessagesRequest', 'deleteThreadRequest'
		]),
		...mapActions('favourites', ['addFavouriteProfile', 'removeFavouriteProfile']),
		...mapMutations('chat', ['setInputState', 'setIsCurrentThreadNew', 'setOnEnterSend', 'setMessagesData', 'setCurrentThreadProperty']),

		isNextFromTheSameSender(message, index) {
			if (index < this.messagesData.length-1) {
				return this.messagesData[index+1].sender.username === message.sender.username;
			}
			return false;
		},
		isNextSeparated(message, index) {
			if (index < this.messagesData.length-1) {
				let date1 = new Date(message.created_at);
				let date2 = new Date(this.messagesData[index+1].created_at);
				return date2 - date1 > this.intervalToSeparateMessages;
			}
			return false;
		},
		isPreviousFromTheSameSender(message, index) {
			if (index > 0) {
				return this.messagesData[index-1].sender.username === message.sender.username;
			}
			return false;
		},
		isPreviousFromYesterday(message, index) {
			if (index > 0) {
				let date1 = new Date(this.messagesData[index-1].created_at);
				let date2 = new Date(message.created_at);

				return date2.getDay() !== date1.getDay();
			}
			return false;
		},
		isPreviousSeparated(message, index) {
			if (index > 0) {
				var date1 = new Date(this.messagesData[index-1].created_at);
				var date2 = new Date(message.created_at);
				return date2 - date1 > this.intervalToSeparateMessages;
			}
			return false;
		},
		checkIsSender(senderUsername) {
			return senderUsername === this.userData.username;
		},
		checkIsSystemMessage(sender) {
			return sender === this.systemSender;
		},
		scrollToBottom() {
			if(this.$refs.chatContainer !== undefined) {
				this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight;
			}
		},
		scrollToNewPosition(scrollHeight, initial) {
			this.$refs.chatContainer.scrollTo(0, scrollHeight - initial )
		},
		updateChat(data) {
			let messages = structuredClone(this.messagesData)
			messages.push({
				content: data.messageContent,
				created_at: data.created_at,
				created_at_date: data.created_at_date,
				sender: data.sender,
				receiver: data.receiver,
				owner: data.owner,
			});

			this.messageContent = null;

			this.setMessagesData({messages: messages});

			this.$nextTick(() => {
				this.scrollToBottom()
			})
		},

		closeThread() {
			router.push({name: 'chats'});
			this.clearCurrentThread();
		},

		addSystemMessage(msg) {
			let messages = structuredClone(this.messagesData)
			messages.push({
				content: msg,
				sender: this.systemSender,
				receiver: this.userData,
				owner: null,
			});

			this.setMessagesData({messages: messages});
		},

		getChatColor(message) {
			return !this.checkIsSender(message.sender.username) && !this.checkIsSystemMessage(message.sender);
		},

		loadMoreMessages() {
			let currPage = this.messagesPagination.current_page + 1;
			this.scrollToBottomPosition = false;
			this.loadMoreMessagesRequest({id: this.currentThread.id, page: currPage});
		},

		showProfileModal(username) {
			this.isShowProfileModal = true;
			this.profileUsername = username;
		},

		showChatNavigationFocusIn() {
			if(this.isChatOpenOnMobile()) {
				this.scrollToBottom();
				if(this.$browserDetect.isIOS) {

				}
			}
		},
		showChatNavigationBlur() {
			if(this.isChatOpenOnMobile()) {
				this.scrollToBottom()
				// if(this.$browserDetect.isIOS) {
				// this.$refs.chatNavigation.style.top = '0px';

				// }
			}
		},
		setViewHeight: function() {
			let vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty('--vh', `${vh}px`)

		},
		resizeHandler() {
			const viewport = window.visualViewport;
			const height = window.visualViewport.height;
			this.$refs.chatNavigation.style.top = `${height - viewport.height + 53}px`;
		},
		chatScrolled() {
			// this.$refs.chatNavigation.style.top = `0px`;
		},

    toggleThreadActionsDropdown() {
      this.isThreadActionsDropdownVisible = !this.isThreadActionsDropdownVisible;
    },
	},

	beforeDestroy() {
		if(this.isMobile) {
			// document.documentElement.style.overflow = 'scroll';
		}
	},
	mounted() {
		if(this.isChatOpenOnMobile()) {
			resizeChatHeader.resizeHeader(this.$browserDetect.isIOS && !this.$browserDetect.isChromeIOS)
			document.documentElement.style.overflow = 'hidden';
		}

		this.setOnEnterSend(this.onEnterSend);
		if(this.currentThread && (!this.currentThread.newThread || this.messagesData.length > 0)) {
			// Listen to new message if thread is chosen
			window.Echo.encryptedPrivate(`chat.${this.currentThread.id}`).listen('MessageSent', (e) => {
				if(e.thread_id === this.currentThread?.id){
					this.updateChat({
						messageContent: e.content,
						created_at: e.created_at,
						created_at_date: e.created_at_date,
						sender: this.currentThread.targetUser,
						receiver: this.userData,
						owner: this.currentThread.ownerUser,
					});

					this.changeReadRequest(this.currentThread);

					if(!e.newThread) {
						this.setCurrentThreadProperty({property: 'systemMessage', value: null});
						let adminMessageIndex = this.messagesData.findIndex(el => el.owner === null);
						if(adminMessageIndex >= 0) {
							this.messagesData.splice(adminMessageIndex, 1);
						}
					}

					if(e.isAdminThread) {
						this.setCurrentThreadProperty({property: 'isThreadClosed', value: !!e.isThreadClosed});
					}
				}
				if(this.currentThread?.newThread) {
					this.currentThread.newThread = false;
				}
			})
		}
	},

	setup() {
		return {
			// Icons
			icons: {
				mdiCheck,
				mdiCheckAll,
				mdiKeyboardReturn
			},
		}
	}

}
</script>

<style lang="scss">
.fixed-chat-height {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

#toolbar-wrap {
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -o-sticky;
	position: -ms-sticky;
	position: -webkit-sticky;
	display: block;
	top: 0;
	width: 100%;
	height: 53px;
	z-index: 1000;
}

.chat-footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
}

.chat-content {
	//margin-bottom: 130px;
	overflow-y: scroll;
}

.chat-inner-content {
	//margin-bottom: 130px;

}

.height-53 {
	height: 53px;
}
.height-127 {
	height: 127px;
}
.top-53 {
	top: 53px;
}
.padding-65 {
	padding-top: 65px;
}
.z-index-999 {
	z-index: 999;
}
#toolbar.down {
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 50ms;
}

</style>