<template>
  <div class="w-full flex flex-col lg:flex-row gap-3 lg:gap-6 items-start lg:justify-between">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "PageContentWithSidebar"
}
</script>
